import React, { useState, useRef } from "react";

import Curve from 'images/curve-white.svg';

import styles from './styles.module.scss';

const Enquire = () => {
  const [submitted, setSubmitted] = useState(false);
  const form = useRef(null)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(form.current!);

    function encode(data: any) {
      return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
    }

    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "enquiryForm",
        ...Object.fromEntries(formData.entries()),
      }),
    })
    setSubmitted(true);
  };

  return (
    <div id="enquire" className={styles.enquire}>
      <img src={Curve} className="curve reverse" />
      <h3>Contact Us</h3>
      {submitted ? (
        <p>Thank you for your enquiry. We will be in contact with you soon</p>
      ) : (
        <form ref={form} name="enquiryForm" onSubmit={handleSubmit} data-netlify-honeypot="bot-field" data-netlify="true">
          <input type="hidden" name="form-name" value="enquiryForm" />
          <input placeholder="Name" name="name" required/>
          <input placeholder="Email" type="email" name="email" required/>
          <input placeholder="Phone" type="phone" name="phone" required/>
          <textarea rows={6} name="enquiry" placeholder="How can we help you?" required/>
          <button type="submit">SUBMIT</button>
        </form>
      )}
    </div>
  );
}

export default Enquire
